<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getUserList" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담사관리</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10" v-if="auth === 'MASTER' || auth ==='ADMIN'">
                            <select  v-model="idxCrmCenter" @change="getUserList()" class="w-150px">
                                <option value="-1">센터선택</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>

                            <input type="text" v-model="name" class="ml-20 w-200px h-40px pd-00 pl-20" placeholder="상담사명">
                            <a class="btn_search ml-10 pointer" @click="getUserList()">Search<span></span></a>
                        </div>
                        <div class="btns mb-30">
                            <a class="btn_new2 pointer" @click="register()">상담사등록</a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>상담사명</th>
                                <th>성별</th>
                                <th>센터</th>
                                <th>전문분야</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="6">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>상담사명</th>
                                <th>성별</th>
                                <th>센터</th>
                                <th>전문분야</th>
                            </tr>
                            <tr v-for="(item, index) of userList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td class="underline pointer blue" @click="detailCard(item.idx)">{{item.name}}</td>
                                <td>{{item.gender || '-'}}</td>
                                <td>{{item.centerName || '-'}}</td>
                                <td>{{item.proName || '-'}}</td>
                            </tr>
                            <tr v-if="userList.length === 0">
                                <td colspan="5">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        name: '', // 상담사 검색 이름
        idxCrmCenter: -1,
        userList: [],
        centerList: [],
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
        auth: localStorage.getItem('auth') || '', //권한
    }),

    mounted() {

        if(sessionStorage.getItem('origin_counselor_pageNum') !== 'undefined' && sessionStorage.getItem('origin_counselor_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_counselor_pageNum'))
        }
        if(sessionStorage.getItem('origin_counselor_name') !== 'undefined' && sessionStorage.getItem('origin_counselor_name') !== null) {
            this.name = sessionStorage.getItem('origin_counselor_name')
        }
        if(sessionStorage.getItem('origin_counselor_center') !== 'undefined' && sessionStorage.getItem('origin_counselor_center') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('origin_counselor_center')
        }

        this.getUserList()
        this.getCenterListAll()
    },

    methods: {

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getUserList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getUserList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getUserList()
            }
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    } else {
                        this.centerList = res.data.centerList
                        alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 검색 api
        getUserList() {
            const auth = localStorage.getItem('auth');
            var params = {
                idxCrmCenter: auth === 'MASTER' || auth === 'ADMIN' ? this.idxCrmCenter : localStorage.getItem('idxCrmCenter'), 
                name: this.name,
                permission: 'Y',
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }

            sessionStorage.setItem('origin_counselor_pageNum', this.pageNum)
            sessionStorage.setItem('origin_counselor_name', this.name)
            sessionStorage.setItem('origin_counselor_center', this.idxCrmCenter)
            this.loading = true;
            this.axios.get('/api/v2/user/list', {
                    params: params
                })
                .then(res => {
                    this.userList = res.data.userList
                    if (res.data.userList) {
                        this.listLength = res.data.userListCount
                    }
                    this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                    this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                    this.pagePart = Math.ceil(this.pageNum / 10)
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        // 상담등록 페이지로 이동
        register() {
            this.$router.push('/origin/counselor_manage_register')
        },

        // 상담수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/counselor_manage_modify?idx=${idx}`)
        },

        // 상담사 카드 페이지로 이동
        detailCard(idx) {
            this.$router.push(`/origin/counselor_manage_card?idx=${idx}`)
        },

        del(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/user/${idx}`, {})
                    .then(res => {
                        
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                return false
            }
        }
    }
}
</script>
